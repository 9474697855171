var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learning-process w-100 d-flex flex-column align-items-center"},[_c('base-header',{attrs:{"title":_vm.$t('學習歷程'),"is-title-bold":!_vm.$device.isMobile,"left-arrow":_vm.$device.isMobile,"left-text":_vm.$device.isMobile ? '' : '返回個人頁',"back-on-click-left":false},on:{"on-click-left":_vm.goBack}}),_c('div',{staticClass:"learning-process-container w-100 d-flex flex-column flex-md-row justify-content-center align-items-center"},[_c('div',{staticClass:"wrapper-calendar scroll-bar"},[_c('LearningCalendar',{attrs:{"active-date":_vm.activeDate,"learning-data":_vm.learningData},on:{"day-click":_vm.dayClick,"month-click":_vm.monthClick}})],1),_c('div',{staticClass:"wrap-operate-panel bg-white pb-md-4 pt-md-3 px-md-4 mt-2 mt-md-0 d-flex flex-column position-relative"},[(!_vm.$device.isMobile)?_c('UserCard',{attrs:{"user-data":_vm.userData,"is-circle":true}}):_vm._e(),_c('div',{staticClass:"learning-list scroll-bar"},[(_vm.isListLoading)?_c('loading-wrapper',{staticClass:"loading"}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"date-title bg-white font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$day(_vm.activeDate).format('YYYY.MM.DD'))+" ")]),(
                _vm.rankUpgradeDate?.length > 0 ||
                _vm.levelUpgradeDate?.length > 0 ||
                _vm.coureseCompleteDate?.length > 0
              )?_c('div',{staticClass:"date-milestone pb-3 pt-2 bg-white"},[_vm._l((_vm.rankUpgradeDate),function(data){return [_c('MilestoneCard',{key:data.createdAt,attrs:{"data":data}})]}),_vm._l((_vm.levelUpgradeDate),function(data){return [_c('MilestoneCard',{key:data.createdAt,attrs:{"data":data}})]}),_vm._l((_vm.coureseCompleteDate),function(data){return [_c('MilestoneCard',{key:data.createdAt,attrs:{"data":data}})]})],2):_vm._e(),_vm._l((_vm.learningDataByDate),function(data){return [(
                  data.type !== 'RANK_UPGRADE' &&
                  data.type !== 'LEVEL_UPGRADE' &&
                  data.type !== 'COURSE_COMPLETE'
                )?_c('LearningCard',{key:data.createdAt,attrs:{"data":data}}):_vm._e()]})],2)],1),(!_vm.$device.isMobile && _vm.passVerification)?_c('div',{staticClass:"pass-verification text-20 text-primary font-weight-bold d-flex justify-content-center align-items-center mt-2 pt-1"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('恭喜通過') + ' ' + _vm.passVerification + ' ' + _vm.$t('檢定'))+" ")]),_c('b-img',{staticClass:"pass-verification-sticker",attrs:{"width":"52","height":"52","srcset":require('@/assets/img/personal/learning-process/handclap-jia.png?srcset')}})],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }