<template>
  <div class="learning-process w-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('學習歷程')"
      :is-title-bold="!$device.isMobile"
      :left-arrow="$device.isMobile"
      :left-text="$device.isMobile ? '' : '返回個人頁'"
      :back-on-click-left="false"
      @on-click-left="goBack"
    ></base-header>
    <div
      class="learning-process-container w-100 d-flex flex-column flex-md-row justify-content-center align-items-center"
    >
      <div class="wrapper-calendar scroll-bar">
        <LearningCalendar
          :active-date="activeDate"
          :learning-data="learningData"
          @day-click="dayClick"
          @month-click="monthClick"
        ></LearningCalendar>
      </div>
      <div
        class="wrap-operate-panel bg-white pb-md-4 pt-md-3 px-md-4 mt-2 mt-md-0 d-flex flex-column position-relative"
      >
        <UserCard
          v-if="!$device.isMobile"
          :user-data="userData"
          :is-circle="true"
        ></UserCard>
        <div class="learning-list scroll-bar">
          <loading-wrapper
            v-if="isListLoading"
            class="loading"
          ></loading-wrapper>
          <div v-else class="content">
            <div class="date-title bg-white font-weight-bold">
              {{ $day(activeDate).format('YYYY.MM.DD') }}
            </div>
            <div
              v-if="
                rankUpgradeDate?.length > 0 ||
                levelUpgradeDate?.length > 0 ||
                coureseCompleteDate?.length > 0
              "
              class="date-milestone pb-3 pt-2 bg-white"
            >
              <!-- <div
                class="milestone-card d-flex py-2 px-3 bg-white align-items-center"
              >
                <div class="verification-day milestone-icon"></div>
                <div class="content flex-fill">棋力升為 1d</div>
                <div class="type text-right">
                  <b-button
                    variant="primary"
                    size="md"
                    class="mx-2 stepType mr-md-1 ml-md-0"
                    pill
                  >
                    {{ $t('里程碑') }}
                  </b-button>
                </div>
              </div>
              <div
                class="milestone-card d-flex py-2 px-3 bg-white align-items-center"
              >
                <div class="levelup-day milestone-icon"></div>
                <div class="content flex-fill">帳號等級升為Lv.999</div>
                <div class="type text-right">
                  <b-button
                    variant="primary"
                    size="md"
                    class="mx-2 stepType mr-md-1 ml-md-0"
                    pill
                  >
                    {{ $t('里程碑') }}
                  </b-button>
                </div>
              </div>
              <div
                class="milestone-card d-flex py-2 px-3 bg-white align-items-center"
              >
                <div class="course-complete-day milestone-icon"></div>
                <div class="content flex-fill">完成「研修進階班」所有課程</div>
              </div> -->
              <template v-for="data in rankUpgradeDate">
                <MilestoneCard
                  :key="data.createdAt"
                  :data="data"
                ></MilestoneCard>
              </template>
              <template v-for="data in levelUpgradeDate">
                <MilestoneCard
                  :key="data.createdAt"
                  :data="data"
                ></MilestoneCard>
              </template>
              <template v-for="data in coureseCompleteDate">
                <MilestoneCard
                  :key="data.createdAt"
                  :data="data"
                ></MilestoneCard>
              </template>
            </div>
            <template v-for="data in learningDataByDate">
              <LearningCard
                v-if="
                  data.type !== 'RANK_UPGRADE' &&
                  data.type !== 'LEVEL_UPGRADE' &&
                  data.type !== 'COURSE_COMPLETE'
                "
                :key="data.createdAt"
                :data="data"
              ></LearningCard>
            </template>
          </div>
        </div>
        <div
          v-if="!$device.isMobile && passVerification"
          class="pass-verification text-20 text-primary font-weight-bold d-flex justify-content-center align-items-center mt-2 pt-1"
        >
          <div>
            {{ $t('恭喜通過') + ' ' + passVerification + ' ' + $t('檢定') }}
          </div>
          <b-img
            class="pass-verification-sticker"
            width="52"
            height="52"
            :srcset="
              require('@/assets/img/personal/learning-process/handclap-jia.png?srcset')
            "
          ></b-img>
        </div>
        <!-- // todo: 里程碑時間較趕，下次更新才會製作 -->
        <!-- <span class="pennant-icon position-absolute"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import UserCard from '@/components/Practice/UserCard';
import LearningCalendar from '@/components/Personal/LearningCalendar';
import LearningCard from '@/components/Personal/LearningCard';
import MilestoneCard from '@/components/Personal/MilestoneCard';

import _ from 'lodash';
import ranksMapping from '@/json/rank.json';
import userService from '@/services/user';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    BaseHeader,
    UserCard,
    LearningCalendar,
    LearningCard,
    MilestoneCard,
  },
  data() {
    return {
      activeDate: this.$day().format(),
      isListLoading: false,
      ranksMapping,
      learningData: [],
      learningDataByDate: [],
      passVerification: null,
      coureseCompleteDate: null,
      rankUpgradeDate: null,
      levelUpgradeDate: null,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  async mounted() {
    BackEvent.addEvent(() => {
      this.goBack();
    });
    const params = {
      startedAt: parseInt(this.$day().startOf('month').valueOf()),
      endedAt: parseInt(this.$day().endOf('month').valueOf()),
    };
    const {data} = await this.getLearningProcess(params);
    this.learningData = data || [];
    this.learningDataByDate = this.filterLearningData(this.activeDate);
  },
  methods: {
    goBack() {
      BackEvent.clearEvents();
      this.$router.push({name: 'personal'});
    },
    getLearningProcess({startedAt, endedAt}) {
      const data = {
        username: this.userData.username,
        startedAt,
        endedAt,
      };
      return userService.getLearningProcess(data);
    },
    async monthClick({month, year}) {
      const params = {
        startedAt: parseInt(
          this.$day(`${year}-${month}-1`).startOf('month').valueOf()
        ),
        endedAt: parseInt(
          this.$day(`${year}-${month}-1`).endOf('month').valueOf()
        ),
      };
      const {data} = await this.getLearningProcess(params);
      this.learningData = data || [];
    },
    dayClick(day) {
      if (day?.date === this.activeDate) return;
      this.activeDate = day?.date || day;
      this.learningDataByDate = this.filterLearningData(day.date);
    },
    filterLearningData(date) {
      if (this.learningData.length <= 0) return [];
      const _filterLearningData = this.learningData.filter((data) => {
        const createdAt = this.$day(data.createdAt).format('YYYY-MM-DD');
        const _date = this.$day(date).format('YYYY-MM-DD');
        return this.$day(createdAt).isSame(_date);
      });
      this.coureseCompleteDate = this.getCoureseComplete(_filterLearningData);
      const verificationDate = this.getVerification(_filterLearningData);
      this.rankUpgradeDate = this.getRankUpgrade(_filterLearningData);
      this.levelUpgradeDate = this.getLevelUpgrade(_filterLearningData);

      if (verificationDate.length > 0) {
        this.passVerification = this.getPassVerification(verificationDate[0]);
      } else {
        this.passVerification = null;
      }

      const newFilterLearningData = _.concat(
        verificationDate,
        _filterLearningData
      );
      return _.uniqBy(newFilterLearningData, 'sk');
    },
    getVerification(data) {
      const _data = data.filter((data) => {
        return data.type === 'VERIFICATION';
      });
      return _.orderBy(
        _data,
        function (data) {
          const skParts = _.split(data.sk, '#');
          const rank = ranksMapping.find((rank) => {
            return rank.value === parseInt(skParts[2]);
          });
          return rank.value;
        },
        'desc'
      );
    },
    // 通過/未通過檢定拆解棋力等級
    getPassVerification(data) {
      const skParts = _.split(data.sk, '#');
      const rank = ranksMapping.find((rank) => {
        return rank.value === parseInt(skParts[2]);
      });
      return rank.label;
    },
    // 置頂
    getCoureseComplete(data) {
      return data.filter((data) => {
        return data.type === 'COURSE_COMPLETE';
      });
    },
    getRankUpgrade(data) {
      return data.filter((data) => {
        return data.type === 'RANK_UPGRADE';
      });
    },
    getLevelUpgrade(data) {
      return data.filter((data) => {
        return data.type === 'LEVEL_UPGRADE';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.learning-process {
  font-family: 'Microsoft YaHei';
  height: 100%;
  @media screen and (min-width: 768px) {
    background: $bgsection;
  }
  .learning-process-container {
    @media screen and (min-width: 768px) {
      height: 100%;
      padding: 24px;
      max-width: 1440px;
    }
  }
  .wrapper-calendar {
    width: 100%;
    @media screen and (min-width: 768px) {
      height: calc(100vh - 56px - 48px); // header高度、高度間距上下24px
      padding-right: 12px;
      overflow: overlay;
    }
  }
  .wrap-operate-panel {
    height: 100%;
    width: 100%;
    @media screen and (min-width: 768px) {
      border-radius: 10px;
      height: calc(100vh - 56px - 48px);
      max-width: 384px;
      margin-left: 4px;
    }
    .learning-list {
      @media screen and (min-width: 768px) {
        overflow: overlay;
        background: $bgcontainer;
        padding: 16px;
        height: 100%;
        border-radius: 3px;
        border: 1px solid #dcdfe5;
      }
    }
    .icon-Pennant {
      color: white;
      font-size: 32px;
      right: 16px;
      top: 16px;
    }
    .pennant-icon {
      font-size: 16px;
      line-height: 38px;
      height: 38px;
      width: 38px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      flex-shrink: 0;
      right: 8px;
      top: 16px;
      cursor: pointer;
      &::after {
        content: '\e957';
        position: absolute;
        font-size: 28px;
        font-family: 'icomoon' !important;
        background: $primary;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        color: white;
        padding-left: 4px;
      }
    }
  }
}
.item-border-top {
  border-top: 1px solid $line-2;
}
.date-title {
  color: $font-grayscale-1;
  padding: 12px 16px;
}
.date-milestone {
  border-top: 1px solid #dcdfe5;
}
</style>
