<template>
  <div
    class="learning-card d-flex py-4 px-3 bg-white item-border-top align-items-center"
  >
    <div class="content flex-fill">
      {{ data.content }}
    </div>
    <div class="type text-right" :class="typeStyle">
      {{ $t(TYPES[data.type]) }}
    </div>
  </div>
</template>

<script>
import {TYPES} from '@/constant/learning';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      TYPES,
    };
  },
  computed: {
    typeStyle() {
      switch (TYPES[this.data.type]) {
        case '檢定':
          return 'text-14 text-accent';
        case '課程':
          return 'text-14 text-infoAndLink';
        case '做題':
          return 'text-14 text-positive';
        case 'AI對弈':
        case '下棋':
          return 'text-14 text-critical';
        case '對戰':
          return 'text-14 text-destructive';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.learning-card {
  .type {
    flex-shrink: 0;
    margin-left: 16px;
  }
}
</style>
