<template>
  <div class="calendar bg-white text-center position-relative">
    <vc-calendar
      ref="calendar"
      class="custom-calendar"
      :masks="masks"
      :attributes="attrs"
      :trim-weeks="$device.isMobile"
      is-expanded
      @dayclick="dayClick"
      @update:from-page="monthClick"
    >
      <template v-slot:day-content="{day, attributes, dayEvents}">
        <div
          v-if="dayEvents.click"
          class="vc-day-content vc-focusable"
          role="button"
          :class="getHighlightClass(attributes)"
          @click="dayEvents.click"
        >
          <span class="day-label">{{ day.day }}</span>
          <figure
            v-if="
              !$device.isMobile &&
              attributes &&
              attributes.length > 0 &&
              attributes[attributes.length - 1].customData
            "
            class="day-sticker"
          >
            <img
              :src="attributes[attributes.length - 1].customData.stickerUrl"
            />
          </figure>
        </div>
      </template>
    </vc-calendar>
    <span
      class="icon-Calendar position-absolute"
      :class="{
        'is-disable': today === $day(activeDate).format('YYYY/MM/DD'),
      }"
      @click="todayClick"
    ></span>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    activeDate: {
      type: [String, Date],
      default: null,
    },
    learningData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      masks: {
        title: 'YYYY.MM',
      },
      today: this.$day().format('YYYY/MM/DD'),
    };
  },
  computed: {
    attrs() {
      return [
        {
          key: 'activeDay',
          highlight: {
            contentClass: 'active',
          },
          dates: this.activeDate,
          order: 0,
        },
        {
          key: 'beforeDay',
          highlight: {
            contentClass: 'before-day',
          },
          dates: {
            end: this.$day().subtract(1, 'day').format(), // Same as before
          },
          order: 1,
        },
        {
          key: 'courseCountLittle',
          highlight: {
            contentClass: 'count-little',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/count-little.svg'),
          },
          dates: this.getCountLittle(),
          order: 2,
        },
        {
          key: 'courseCountSome',
          highlight: {
            contentClass: 'count-some',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/count-some.svg'),
          },
          dates: this.getCountSome(),
          order: 3,
        },
        {
          key: 'courseCountMany',
          highlight: {
            contentClass: 'count-many',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/count-many.svg'),
          },
          dates: this.getCountMany(),
          order: 4,
        },
        {
          key: 'courseCompleteDay',
          highlight: {
            contentClass: 'course-complete-day',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/course-complete.svg'),
          },
          dates: this.getCourseCompleteDay(),
          order: 5,
        },
        {
          key: 'levelUpDay',
          highlight: {
            contentClass: 'level-up-day',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/level-up.svg'),
          },
          dates: this.getLevelUpDay(),
          order: 6,
        },
        {
          key: 'verificationDay',
          highlight: {
            contentClass: 'verification-day',
          },
          customData: {
            stickerUrl: require('@/assets/img/personal/learning-process/verification.svg'),
          },
          dates: this.getVerificationDay(),
          order: 7,
        },
      ];
    },
    learningDataByDate() {
      const _learningData = _.map(this.learningData, (data) => {
        data.date = this.$day(data.createdAt).format('YYYY-MM-DD');
        return data;
      });
      return _.groupBy(_learningData, 'date');
    },
  },
  methods: {
    monthClick(month) {
      this.$emit('month-click', month);
    },
    dayClick(day) {
      this.$emit('day-click', day);
    },
    async todayClick() {
      const calendar = this.$refs.calendar;
      calendar.focusDate(new Date());
      this.dayClick(new Date());
    },
    getHighlightClass(attributes) {
      if (!attributes) return;
      const highlightClasses = [];
      for (const attr of attributes) {
        if (attr?.highlight?.base?.contentClass)
          highlightClasses.push(attr.highlight.base.contentClass);
      }
      if (this.$device.isMobile) highlightClasses.push('is-mobile');
      return highlightClasses;
    },
    getVerificationDay() {
      return _.map(this.learningData, (data) => {
        if (data.type === 'VERIFICATION') return data.createdAt;
      });
    },
    getLevelUpDay() {
      return _.map(this.learningData, (data) => {
        if (data.type === 'LEVEL_UPGRADE') return data.createdAt;
      });
    },
    getCourseCompleteDay() {
      return _.map(this.learningData, (data) => {
        if (data.type === 'COURSE_COMPLETE') return data.createdAt;
      });
    },
    getCountLittle() {
      return _.map(this.learningDataByDate, (data, key) => {
        if (data.length > 0 && data.length <= 3) return key;
      });
    },
    getCountSome() {
      return _.map(this.learningDataByDate, (data, key) => {
        if (data.length >= 4 && data.length <= 6) return key;
      });
    },
    getCountMany() {
      return _.map(this.learningDataByDate, (data, key) => {
        if (data.length >= 7) return key;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
  width: 100%;
  padding: 0 24px;
  @media screen and (min-width: 768px) {
    border-radius: 10px;
    width: 100%;
    padding: 20px 24px 40px;
  }
  @media screen and (min-width: 1440px) {
    height: calc(100vh - 56px - 48px);
    padding: 20px 40px 40px;
  }
  /deep/ .custom-calendar {
    width: 100%;
    &.vc-container {
      --weekday-bg: #dcdfe5;
      --day-border: 1px solid #dcdfe5;
      --day-width: 38px;
      --day-height: 38px;
      --day-line-height: 38px;
      --text-xl: 20px;
      border-radius: 0;
      border: none;
      @media screen and (min-width: 768px) {
        --day-width: auto;
        --day-height: 124px;
      }
    }
    & .vc-header {
      padding: 20px 0;
      .vc-title {
        color: $font-normal;
        line-height: 30px;
        font-weight: 700;
        @media screen and (min-width: 768px) {
          line-height: 40px;
        }
      }
    }
    .vc-arrows-container {
      padding: 20px 0;
      width: 224px;
      left: calc(50% - 112px);
      @media screen and (min-width: 768px) {
        width: 400px;
        left: calc(50% - 200px);
      }
      .vc-arrow:hover {
        background: transparent;
      }
      .vc-arrow {
        color: $font-grayscale-2;
        .vc-svg-icon {
          @media screen and (min-width: 768px) {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
    & .vc-weeks {
      padding: 0;
      margin-bottom: 8px;
      @media screen and (min-width: 768px) {
        border: var(--day-border);
        margin-bottom: 0;
      }
    }
    & .vc-weekday {
      color: $font-grayscale-3;
      font-size: var(--text-base);
      padding: 0;
      line-height: 30px;
      margin-bottom: 8px;
      @media screen and (min-width: 768px) {
        color: white;
        font-size: var(--text-xl);
        background: var(--weekday-bg);
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 0;
      }
    }
    & .vc-day-content {
      font-size: var(--text-base);
      line-height: var(--day-line-height);
      height: var(--day-height);
      width: var(--day-width);
      font-weight: var(--font-normal);
      border-radius: 50%;
      position: relative;
      @media screen and (min-width: 768px) {
        border: 2px solid transparent;
      }
      @media screen and (min-width: 768px) {
        flex-direction: column;
        border-radius: 0;
        justify-content: start;
        .day-label {
          border-radius: 50%;
          width: 32px;
          line-height: 28px;
          margin: 0;
          border: 2px solid transparent;
          display: inline-block;
          margin: 8px 0 0 5px;
          align-self: flex-start;
          position: absolute;
        }
        .day-sticker {
          flex: 1 0 0;
          min-height: 0;
          display: flex;
          margin: 24px 0 0;
          @media screen and (min-width: 1440px) {
            margin: 16px 8px 0px;
          }
          > img {
            max-height: 100%;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            width: 90px;
            height: 90px;
            object-fit: cover;
            @media screen and (min-width: 1024px) {
              width: auto;
              height: auto;
              object-fit: initial;
            }
          }
        }
      }
      @media screen and (min-width: 1440px) {
        height: calc((100vh - 56px - 48px - 60px - 80px - 34px - 6px) / 6);
      }
      &:hover {
        border: 2px solid $primary;
        @media screen and (min-width: 768px) {
          background-color: transparent;
        }
      }
      &.before-day {
        background: $bgcontainer;
        &:hover {
          background: $bgcontainer;
        }
      }
      &.is-mobile.count-little {
        background: #ffc06933;
      }
      &.is-mobile.count-some {
        background: #ffc06966;
      }
      &.is-mobile.count-many {
        background: #ffc06999;
      }
      &.is-mobile.course-complete-day {
        &::after {
          position: absolute;
          content: '\e94d';
          font-size: 32px;
          font-family: 'icomoon' !important;
          background: $secondary;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          color: white;
        }
      }
      &.is-mobile.course-complete-day.active {
        &::after {
          line-height: 34px;
        }
      }
      &.is-mobile.verification-day {
        &::after {
          position: absolute;
          content: '\e922';
          font-size: 32px;
          font-family: 'icomoon' !important;
          background: $secondary;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          color: white;
        }
      }
      &.is-mobile.verification-day.active {
        &::after {
          line-height: 34px;
        }
      }
      &.active {
        border: 2px solid $primary;
      }
    }
    & .vc-day {
      margin-bottom: 12px;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        &:not(.on-bottom) {
          border-bottom: var(--day-border);
        }
        &:not(.on-right) {
          border-right: var(--day-border);
        }
      }
    }
  }
  .icon-Calendar {
    color: $primary;
    font-size: 32px;
    right: 16px;
    top: 16px;
    @media screen and (min-width: 768px) {
      right: 24px;
      top: 40px;
    }
    @media screen and (min-width: 1440px) {
      right: 40px;
      top: 40px;
    }
    cursor: pointer;
    &.is-disable {
      color: $font-grayscale-3;
      cursor: initial;
    }
  }
}
</style>
