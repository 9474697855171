<template>
  <div
    border-variant="white"
    class="user-card position-relative d-flex flex-md-column bg-white align-items-center"
  >
    <img
      :srcset="avatarSrc"
      class="img-avatar"
      :class="{'rounded-circle': isCircle}"
    />
    <div class="name text-18 font-weight-bold">
      {{ userData.nickName }} - {{ ranksMapping[userData.rank].label }}
    </div>
  </div>
</template>

<script>
import ranksMapping from '@/json/rank.json';

export default {
  props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ranksMapping,
    };
  },
  computed: {
    avatarSrc() {
      return require(`@/assets/img/personal/avatar/${
        this.userData.avatar || '0000'
      }.png?srcset`);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  padding: 20px;
  box-shadow: $card-box-shadow;
  @media screen and (min-width: 768px) {
    padding: 8px 0 20px;
    box-shadow: none;
  }
  .img-avatar {
    margin-right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    @media screen and (min-width: 768px) {
      width: 100px;
      height: 100px;
      margin-right: 0;
      margin-bottom: 16px;
      border-radius: 3px;
    }
  }
}
</style>
