var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar bg-white text-center position-relative"},[_c('vc-calendar',{ref:"calendar",staticClass:"custom-calendar",attrs:{"masks":_vm.masks,"attributes":_vm.attrs,"trim-weeks":_vm.$device.isMobile,"is-expanded":""},on:{"dayclick":_vm.dayClick,"update:from-page":_vm.monthClick},scopedSlots:_vm._u([{key:"day-content",fn:function({day, attributes, dayEvents}){return [(dayEvents.click)?_c('div',{staticClass:"vc-day-content vc-focusable",class:_vm.getHighlightClass(attributes),attrs:{"role":"button"},on:{"click":dayEvents.click}},[_c('span',{staticClass:"day-label"},[_vm._v(_vm._s(day.day))]),(
              !_vm.$device.isMobile &&
              attributes &&
              attributes.length > 0 &&
              attributes[attributes.length - 1].customData
            )?_c('figure',{staticClass:"day-sticker"},[_c('img',{attrs:{"src":attributes[attributes.length - 1].customData.stickerUrl}})]):_vm._e()]):_vm._e()]}}])}),_c('span',{staticClass:"icon-Calendar position-absolute",class:{
        'is-disable': _vm.today === _vm.$day(_vm.activeDate).format('YYYY/MM/DD'),
      },on:{"click":_vm.todayClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }