<template>
  <div class="milestone-card d-flex py-2 px-3 bg-white align-items-center">
    <div class="milestone-icon" :class="typeClass"></div>
    <div class="content flex-fill">{{ data.content }}</div>
    <!-- // todo: 里程碑時間較趕，下次更新才會製作 -->
    <!-- <div class="type text-right">
      <b-button
        variant="primary"
        size="md"
        class="mx-2 stepType mr-md-1 ml-md-0"
        pill
      >
        {{ $t('里程碑') }}
      </b-button>
    </div> -->
  </div>
</template>

<script>
import {TYPES} from '@/constant/learning';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      TYPES,
    };
  },
  computed: {
    typeClass() {
      switch (this.data.type) {
        case 'RANK_UPGRADE':
          return 'rank-upgrade-day';
        case 'LEVEL_UPGRADE':
          return 'level-upgrade-day';
        case 'COURSE_COMPLETE':
          return 'course-complete-day';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.milestone-card {
  --day-width: 32px;
  --day-height: 32px;
  --day-line-height: 32px;
  --text-xl: 20px;
  border-radius: 0;
  min-height: 50px;
  .btn-milestone {
    flex-shrink: 0;
    margin-left: 10px;
  }
  .milestone-icon {
    font-size: 16px;
    line-height: var(--day-line-height);
    height: var(--day-height);
    width: var(--day-width);
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 0;
    &::after {
      position: absolute;
      font-size: 24px;
      font-family: 'icomoon' !important;
      background: $secondary;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      color: white;
      padding-left: 4px;
    }
  }
  .rank-upgrade-day {
    &::after {
      content: '\e922';
    }
  }
  .course-complete-day {
    &::after {
      content: '\e94d';
    }
  }
  .level-upgrade-day {
    &::after {
      content: '\e956';
      padding-left: 5px;
      padding-top: 1px;
    }
  }
}
</style>
