import {clientVersion} from '@/constant/env';

const TYPES = {
  COURSE_VIDEO: '課程',
  COURSE_COMPLETE: '課程',
  SPECIAL_TRAINING: '做題',
  SPECIAL_TRAINING_RANK_COMPLETE: '做題',
  AI_GAME: clientVersion === 'hjj-jungo' ? 'AI對弈' : '下棋',
  AI_GAME_COMPLETE: clientVersion === 'hjj-jungo' ? 'AI對弈' : '下棋',
  VERIFICATION: '檢定',
  VERIFICATION_FAIL: '檢定',
  LIVE_COURSE: '課程',
  PVP: '對戰',
  AI_GAME_EVERY: clientVersion === 'hjj-jungo' ? 'AI對弈' : '下棋',
  PVP_EVERY: '對戰',
  COURSE_SPECIAL_TRAINING_EVERY: '做題',
  SPECIAL_TRAINING_EVERY: '做題',
  VERIFICATION_TSUME: '做題',
  VERIFICATION_AI_GAME: clientVersion === 'hjj-jungo' ? 'AI對弈' : '下棋',
};

export {TYPES};
